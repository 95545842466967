import ReactDOM from 'react-dom';
import './index.css';

import WebFont from 'webfontloader';

import { makeMainRoutes } from './App/routes';

WebFont.load({
  google: {
    families: ['Raleway:300,400,700', 'sans-serif']
  }
})

const routes = makeMainRoutes();

ReactDOM.render(
  routes,
  document.getElementById('root')
);

import { makeSSOUrl } from "./utils";

export default [
  {
    applicationId: 42,
    permissionsId: [],
    name: "Utransfer",
    type: "web",
    logoHeight: 60,
    logo: "/img/novas-logos/utransfer.png",
    subtitle: "Roteirização e Monitoramento",
    url: {
      adfs: makeSSOUrl(
        "login?sso=globo&redirect=https://utransfer-v2.grupounicad.com.br/login/sso"
      ),
      saml2: makeSSOUrl(
        "login?sso=globo-saml2&redirect=https://utransfer-v2.grupounicad.com.br/login/sso"
      ),
    },
    color: "#FFBE1D",
  },
  {
    applicationId: 41,
    permissionsId: [],
    name: "Utrack",
    type: "mobile",
    logoHeight: 60,
    logo: "/img/novas-logos/utrack.png",
    subtitle: "Rastreamento Seguro",
    url: {
      adfs: makeSSOUrl(
        "login?sso=globo&redirect=utrack://utrack.grupounicad.com.br/redirect"
      ),
      saml2: makeSSOUrl(
        "login?sso=globo-saml2&redirect=utrack://utrack.grupounicad.com.br/redirect"
      ),
    },
    color: "#FF7D00",
  },
  {
    applicationId: 1,
    permissionsId: [],
    name: "Umessenger",
    type: "web",
    logoHeight: 60,
    logo: "/img/novas-logos/umessenger-web.png",
    subtitle: "Comunicação Corporativa",
    url: {
      adfs: makeSSOUrl(
        "login?sso=globo&redirect=https://umessenger.grupounicad.com.br/login/sso"
      ),
      saml2: makeSSOUrl(
        "login?sso=globo-saml2&redirect=https://umessenger.grupounicad.com.br/login/sso"
      ),
    },
    color: "#0039A6",
  },
  {
    applicationId: 1,
    permissionsId: [103, 104],
    name: "Umessenger",
    type: "mobile",
    logoHeight: 60,
    logo: "/img/novas-logos/umessenger-app.png",
    subtitle: "Comunicação Corporativa",
    url: {
      adfs: makeSSOUrl(
        "login?sso=globo&redirect=umessengerapp://umessengerapp/redirect"
      ),
      saml2: makeSSOUrl(
        "login?sso=globo-saml2&redirect=umessengerapp://umessengerapp/redirect"
      ),
    },
    color: "#009DDB",
  },
  {
    applicationId: 21,
    permissionsId: [],
    name: "CARE",
    type: "web",
    logoHeight: 60,
    logo: "/img/novas-logos/care-web.png",
    subtitle: "Gestão de Ocorrências",
    url: {
      adfs: makeSSOUrl(
        "login?sso=globo&redirect=https://care.grupounicad.com.br/cgi-bin/CARE_Redirect.php"
      ),
      saml2: makeSSOUrl(
        "login?sso=globo-saml2&redirect=https://care.grupounicad.com.br/cgi-bin/CARE_Redirect.php"
      ),
    },
    color: "#E51D9B",
  },
  {
    applicationId: 21,
    permissionsId: [99],
    name: "CARE",
    type: "mobile",
    logoHeight: 60,
    logo: "/img/novas-logos/care-mobile.png",
    subtitle: "Gestão de Ocorrências",
    url: {
      adfs: makeSSOUrl(
        "login?sso=globo&redirect=care://care.grupounicad.com.br/redirect"
      ),
      saml2: makeSSOUrl(
        "login?sso=globo-saml2&redirect=care://care.grupounicad.com.br/redirect"
      ),
    },
    color: "#9B2C98",
  },
  {
    applicationId: 36,
    permissionsId: [],
    name: "CAE",
    type: "web",
    logoHeight: 48,
    logoStyle: {
      marginTop: 6,
    },
    logo: "/img/novas-logos/bpu-web.png",
    subtitle: "Atendimento Emergêncial",
    url: {
      adfs: makeSSOUrl(
        "login?sso=globo&redirect=https://bpu.grupounicad.com.br/login/sso"
      ),
      saml2: makeSSOUrl(
        "login?sso=globo-saml2&redirect=https://bpu.grupounicad.com.br/login/sso"
      ),
    },
    color: "#E70033",
  },
  {
    applicationId: 36,
    permissionsId: [57],
    name: "BPU",
    type: "mobile",
    logoHeight: 60,
    logo: "/img/novas-logos/bpu-mobile.png",
    subtitle: "Acionamento de Pânico",
    url: {
      adfs: makeSSOUrl("login?sso=globo&redirect=bpuapp://bpuapp/redirect"),
      saml2: makeSSOUrl(
        "login?sso=globo-saml2&redirect=bpuapp://bpuapp/redirect"
      ),
    },
    color: "#009946",
  },
  {
    applicationId: 39,
    permissionsId: [],
    name: "Risk",
    nameTwo: "Assessment",
    logoHeight: 70,
    logo: "/img/novas-logos/risk-assessment.png",
    type: "web",
    subtitle: "Análise e Avaliação de Risco",
    url: {
      adfs: makeSSOUrl(
        "login?sso=globo&redirect=https://riskassessment.grupounicad.com.br/Analise_Risco_Redirect.php"
      ),
      saml2: makeSSOUrl(
        "login?sso=globo-saml2&redirect=https://riskassessment.grupounicad.com.br/Analise_Risco_Redirect.php"
      ),
    },
    color: "#ff7d00",
  },
  {
    applicationId: 50,
    permissionsId: [],
    name: "Geo",
    nameTwo: "Analytics",
    logoHeight: 44,
    logoStyle: {
      marginTop: 6,
    },
    logo: "/img/novas-logos/geo_analytics.png",
    type: "web",
    subtitle: "",
    url: {
      adfs: makeSSOUrl(
        "login?sso=globo&redirect=https://geoanalytics.grupounicad.com.br/login/sso"
      ),
      saml2: makeSSOUrl(
        "login?sso=globo-saml2&redirect=https://geoanalytics.grupounicad.com.br/login/sso"
      ),
    },
    color: "#0039a6",
  },
  {
    applicationId: 44,
    permissionsId: [],
    name: "UAdmin",
    type: "web",
    subtitle: "Gerência de  Usuários e Permissões",
    url: {
      adfs: makeSSOUrl(
        "login?sso=globo&redirect=https://customer.grupounicad.com.br/login/sso"
      ),
      saml2: makeSSOUrl(
        "login?sso=globo-saml2&redirect=https://customer.grupounicad.com.br/login/sso"
      ),
    },
    color: "#fa8720",
  },
  {
    applicationId: 73,
    permissionsId: [],
    name: "SMN",
    type: "mobile",
    subtitle: "Comunicação Corporativa",
    logo: "/img/novas-logos/SMN.png",
    url: {
      adfs: makeSSOUrl(
        "login?sso=globo&redirect=https://smn.grupounicad.com.br/login/sso"
      ),
      saml2: makeSSOUrl(
        "login?sso=globo-saml2&redirect=https://smn.grupounicad.com.br/login/sso"
      ),
    },
    color: "#2a93a7",
  },
];

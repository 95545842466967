import React from 'react'

export default ({ children }) => (
    <div className="feeback">
        <div className="feeback-center">
            <div className="container-logo">
                <img src="img/logo.png" alt="Logo Globo" />
                <p>{children}</p>
            </div>
        </div>
    </div>
)
import PassportApi from './PassportApi';

export default class AuthService {
    static getApplications(token) {
        return new Promise((resolve, reject) => {
            PassportApi.get('/api/self/applications', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => resolve(response.data))
            .catch(error => resolve([41]));
        })
    }
};

export const AUTHENTICATION_METHOD = {
    SAML2: 1,
    ADFS: 2
};

export default class AuthenticationMethod {
    static isSaml2(method) {
        return AUTHENTICATION_METHOD.SAML2 === method;
    }

    static isAdfs(method) {
        return AUTHENTICATION_METHOD.ADFS === method;
    }
}
import React, { Component } from "react";

import PanelMain from "../Panel/PanelMain";

import Exception from "../Exception/Exception";

import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';

import "./App.css";

import userIcon from "./user.svg";
import syncIcon from "../assets/icons/sync_alt_white_24dp.svg";

import { makeSSOUrl } from '../utils';
import AuthService from '../services/AuthService';
import { AUTHENTICATION_METHOD } from '../AuthenticationMethod';

class App extends Component {

  state = {
    auth: null,
    attemptExpired: false,
  };

  componentDidMount = () => {
    const _attempt = localStorage.getItem("_attempt");
    const _lastAttempt = localStorage.getItem("_last_attempt") || null;
    const _applications = sessionStorage.getItem("_applications");

    if (!_applications) {
      sessionStorage.getItem("_applications", JSON.stringify([]));
    }

    setTimeout(() => {
      if (_attempt >= 3 && _lastAttempt !== null) {
        this._tryVerifyAttemptExpired();
      } else {
        this._tryAuthentication();
      }
    }, 200);
  };

  _tryAuthentication = async () => {
    try {
      const auth = await this._tryPassportAuthentication();
      this.setState({ auth });
      this.props.history.replace("/");
      this._clearStorage();
    } catch (err) {
      const authenticationMethod = localStorage.getItem('authenticationMethod');

      if (authenticationMethod !== null) {
        this._trySingleSignOnAuthentication();
      }
    }
  };

  _tryPassportAuthentication = async () => {
    return new Promise(async (resolve, reject) => {
      const auth = JSON.parse(sessionStorage.getItem("_auth"));

      if (auth) {
        const headers = new Headers();

        headers.append("Authorization", `Bearer ${auth.access_token}`);

        const response = await fetch(makeSSOUrl('logged'),{ headers });

        return response.status === 200 ? resolve(auth) : reject(null);
      } else {
        reject(null);
      }
    });
  };

  _trySingleSignOnAuthentication = async () => {
    try {
      const token = await this._loadingAccessToken();

      const response = await fetch(makeSSOUrl(`session?token=${token}`));

      if (response.status === 404) {
        this._redirectToSSOAuthentication();

        const date = new Date();

        localStorage.setItem(
          "_attempt",
          (localStorage.getItem("_attempt") || 0) + 1
        );
        localStorage.setItem("_last_attempt", date);
      } else if (response.status === 200) {
        const auth = await response.json();
        sessionStorage.setItem("_auth", JSON.stringify(auth));

        const applciations = await AuthService.getApplications(auth.access_token)
        sessionStorage.setItem("_applications", JSON.stringify(applciations));

        this.setState({ auth });
        this.props.history.replace("/");
        this._clearStorage();
      }
    } catch (err) {
      this._redirectToSSOAuthentication();
    }
  };

  _tryVerifyAttemptExpired = () => {
    const _lastAttempt = localStorage.getItem("_last_attempt") || null;

    const now = new Date().getTime();

    const lastAttempt = new Date(_lastAttempt).getTime();
    const diffTime = (now - lastAttempt) / 1000; // Convert Time to Seconds

    const diffTimeMinute = diffTime / 60;

    this.setState({ attemptExpired: Math.ceil(5 - diffTimeMinute) });

    if (diffTimeMinute > 5) {
      this._clearStorage();
      this._tryAuthentication();
    } else {
      setTimeout(this._tryVerifyAttemptExpired, 15000);
    }
  };

  _clearStorage = () => {
    localStorage.removeItem("_attempt");
    localStorage.removeItem("_last_attempt");
  };

  _redirectToSSOAuthentication = (redirectUrl = null, newTab = false) => {
    if (redirectUrl === null) {
      redirectUrl = window.location.origin;
    }

    let target = newTab ? '_blank' : '_self';

    window.open( makeSSOUrl(`login?sso=globo-saml2&redirect=${redirectUrl}`), target);
  };

  _loadingAccessToken = () => {
    const { search } = this.props.location;

    return new Promise((resolve, reject) => {
      let accessToken = null;

      search
        .substr(1)
        .split("&")
        .forEach(param => {
          const [name, value] = param.split("=");
          if (name === "access_token") {
            accessToken = value;
          }
        });

      return accessToken ? resolve(accessToken) : reject(null);
    });
  };

  _startAuthentication = (authenticationMethod) => {
    localStorage.setItem('authenticationMethod', authenticationMethod);
    this._tryAuthentication();
  };

  _changeAuthenticationMethod = () => {
    localStorage.removeItem('authenticationMethod');
    sessionStorage.removeItem('_auth');
    this.setState({auth: null});
  };

  render() {
    const { attemptExpired, auth } = this.state;

    if (!auth && attemptExpired && attemptExpired >= 0) {
      return (
        <Exception>
          Número de tentativas de autenticação excedido.
          <br />
          Por favor, tente novamente em {attemptExpired} minutos.
        </Exception>
      );
    }

    const authenticationMethod = parseInt(localStorage.getItem('authenticationMethod'), 10);

    if (!authenticationMethod) {
      return (
        <Exception>
          <span style={{maxWidth: 300, justifyContent: 'space-around', display: 'flex', marginTop: 30, marginRight: 'auto', marginLeft: 'auto'}}>
            <Button variant="contained" size="large" color="primary" onClick={() => this._startAuthentication(AUTHENTICATION_METHOD.SAML2)}>OpenConnect</Button>
          </span>
        </Exception>
      );
    }

    if (!auth) {
      return <Exception>Carregando...</Exception>;
    }

    const [firstName] = auth.user.name.split(" ");

    return (
      <div className="root">
        <Grid container className="header-bar">

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ marginLeft: 10, marginRight: 20 }} >

              <Grid item sm={4}>
                <div className="header-logo">
                  <img src="img/logo.png" alt="Logo Globo" />
                  <div className="header-sublogo">SIG<span className="last-letter">S</span></div>
                </div>
              </Grid>
              <Grid item sm={4}>
                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>

                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={this._changeAuthenticationMethod}
                    style={{marginRight: 15}}
                    >
                      <img
                        src={syncIcon}
                        alt="Icone de troca de autenticação"
                        title="Trocar autenticação"
                      />
                    </Button>

                  <p style={{ color: '#FFF', textAlign: 'end' }}>
                    <img
                      src={userIcon}
                      style={{ marginRight: 10 }}
                      alt="Icone do usuário"
                    />
                    <strong>{firstName}</strong>
                  </p>
                </div>
              </Grid>
            </Grid>

        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item sm={12} className="title">
            <h3>Sistema Integrado de Gestão de <br/>Segurança</h3>
          </Grid>
        </Grid>
        <PanelMain itemOnClick={this._redirectToSSOAuthentication} />
        <footer className="footer" />
      </div>
    );
  }
}

export default App;

import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";

import App from "./App";

export const makeMainRoutes = () => (
  <Router basename="/" >
    <Route path="/" exact={true}  render={props => <App {...props} />} />
  </Router>
);

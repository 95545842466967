import React, { Component } from "react";

import Container from "@material-ui/core/Container";

import './PanelMain.css'

import apps from '../content'

const PanelMainItem = ({ app, onClick }) => {

  const buttonProps = {
    className: 'item'
  };

  buttonProps.href = app.url.saml2;
  buttonProps.component = 'a';
  buttonProps.target = '_blank';

  if('disabled' in app) {
    buttonProps.className = 'item item-disabled';
  }

  return (
    <a {...buttonProps} style={{ background: app.color }}>
      <div className="content">
        {app.logo !== undefined ? (
          <img src={app.logo} style={{ maxWidth: '100%', maxHeight: app.logoHeight, ...(app.logoStyle || {}) }} alt={app.name} />
        ) : (
          <span>{app.name}</span>
        )}
      </div>
      <div className="content-subtitle">
        {app.subtitle}
      </div>
    </a>
  )
}

export default class PanelMain extends Component {
  render() {
    const applications = JSON.parse(sessionStorage.getItem('_applications'));
    const cards = apps.filter(app => {
      let application = null;

      for (let i = 0; i < applications.length; i++) {
        if (applications[i].id === app.applicationId) {
          application = applications[i];
        }
      }

      if (!application) {
        return false;
      }

      if (app.permissionsId.length === 0) {
        return true;
      }

      let permissions = application.permissions;
      for (let i = 0; i < application.permissions.length; i++) {
        if (app.permissionsId.indexOf(permissions[i].id) !== -1) {
          return true;
        }
      }

      return false;
    });

    return (
      <Container className="panel-main" style={{ width: '100%' }}>
        <div className="container-apps" >
          {cards.map((app, key) => <PanelMainItem app={app} key={key} onClick={this.props.itemOnClick} />)}
        </div>
      </Container>
    );
  }
}
